let VideoEditor = {
    version: "10.0.2.422",
    releaseDD: "06",
    releaseMM: "08",
    releaseYYYY: "2024",
    size: "172.27",
    platforms: "Windows 11, 10, 8.1, 8, 7, Vista, XP (no Mac OS/ Linux support)"
};

let VideoReMaker = {
    version: "7.0.2.283",
    releaseDD: "06",
    releaseMM: "08",
    releaseYYYY: "2024",
    size: "61.84",
    platforms: "Windows 11, 10, 8.1, 8, 7, Vista, XP (no Mac OS/ Linux support)"
};

let VideoConverter = {
    version: "13.0.4.723",
    releaseDD: "06",
    releaseMM: "08",
    releaseYYYY: "2024",
    size: "81.56",
    platforms: "Windows 11, 10, 8.1, 8, 7, Vista, XP (no Mac OS/ Linux support)"
};

let MediaPlayer = {
    version: "5.7.2.160",
    releaseDD: "06",
    releaseMM: "08",
    releaseYYYY: "2024",
    size: "106.59",
    platforms: "Windows 11, 10, 8.1, 8, 7, Vista, XP (no Mac OS/ Linux support)"
};

let AudioEditor = {
    version: "10.5.2.579",
    releaseDD: "06",
    releaseMM: "08",
    releaseYYYY: "2024",
    size: "48.24",
    platforms: "Windows 11, 10, 8.1, 8, 7, Vista, XP (no Mac OS/ Linux support)"
};

let AudioConverter = {
    version: "10.5.2.644",
    releaseDD: "06",
    releaseMM: "08",
    releaseYYYY: "2024",
    size: "50.22",
    platforms: "Windows 11, 10, 8.1, 8, 7, Vista, XP (no Mac OS/ Linux support)"
};

export {VideoEditor, VideoReMaker, VideoConverter, MediaPlayer, AudioEditor, AudioConverter};